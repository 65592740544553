<template>
    <div>
        <div class="uk-padding-small">
            <vk-breadcrumb class="uk-margin-remove">
                <vk-breadcrumb-item :disabled="true">Liste des sous-traitants ou prestataires</vk-breadcrumb-item>
            </vk-breadcrumb>
        </div>
        <div class="uk-padding-small">
            <vue-good-table
                mode="remote"
                @on-page-change="onPageChange"
                @on-sort-change="onSortChange"
                @on-column-filter="onColumnFilter"
                @on-per-page-change="onPerPageChange"
                @on-search="onSearch"
                :totalRows="remoteData.totalRecords"
                :is-loading="workerListIsUpdating"
                :columns="columns"
                :rows="remoteData.rows"
                :search-options="{ enabled: true }"
                :pagination-options="pagination"
                :isLoading.sync="isLoading"
            >
                <template v-slot:table-row="props">
                    <div v-if="props.column.field === 'buildingTrades'" class="d-flex gap-3">
                        <span
                            v-for="trade in props.formattedRow[props.column.field]"
                            :key="'trade-' + trade.id"
                            class="badge bg-primary"
                            >{{ trade.value }}</span
                        >
                    </div>
                    <div
                        v-else-if="props.column.field === 'actions'"
                        class="d-flex justify-content-end align-items-center"
                    >
                        <div class="btn-group">
                            <button
                                type="button"
                                class="btn btn-warning dropdown-toggle"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                            >
                                Retirer de ma liste
                            </button>
                            <ul class="dropdown-menu bg-danger">
                                <li>
                                    <a
                                        class="dropdown-item text-white"
                                        href="#"
                                        @click.prevent="detachCompany(props.row.company.registrationNumber)"
                                        >Confirmer</a
                                    >
                                </li>
                            </ul>
                        </div>
                    </div>
                </template>
            </vue-good-table>
        </div>
    </div>
</template>

<script>
import backendApi from '@/backend/api'
import { mapState } from 'vuex'
import store from '@/store'

export default {
    name: 'WorkersList',
    data: () => ({
        columns: [
            {
                label: 'Corps de métiers connus',
                field: 'buildingTrades',
            },
            {
                label: 'Compagnie',
                field: 'company.name',
            },
            {
                label: 'SIREN',
                field: 'company.registrationNumber',
            },
            {
                label: 'Actions',
                field: 'actions',
                width: '1%',
            },
        ],
        pagination: {
            enabled: true,
            perPage: 10,
            position: 'both',
            perPageDropdown: [10, 25, 50],
            dropdownAllowAll: false,
            setCurrentPage: 1,
            jumpFirstOrLast: true,
            firstLabel: 'Début',
            lastLabel: 'Fin',
            nextLabel: 'Suiv.',
            prevLabel: 'Préc.',
            rowsPerPageLabel: 'Lignes par page ',
            ofLabel: 'de',
            pageLabel: 'page',
            allLabel: 'Tous',
            // infoFn: (params) => `Page ${params.currentPage}`,
        },
        isLoading: false,
        serverParams: {
            extraFilter: {},
            columnFilters: {},
            sort: [],
            page: 1,
            perPage: 10,
        },
        remoteData: {
            rows: [],
            totalRecords: 0,
        },
    }),
    computed: {
        ...mapState('workers', { workersList: 'list', workerListIsUpdating: 'listIsUpdating' }),
    },
    methods: {
        detachCompany(companyRegistrationNumber) {
            return backendApi
                .detachCompanyFunc(store.state.login.user.token, companyRegistrationNumber)
                .then(() => {
                    store.dispatch('notifications/addNotification', {
                        status: 'success',
                        message: 'Entreprise supprimée',
                    })
                    store.dispatch('workers/updateWorkers')
                })
                .catch(backendApi.notificationOnError)
        },
        updateParams(newProps) {
            this.serverParams = Object.assign({}, this.serverParams, newProps)
        },
        onPageChange(params) {
            this.updateParams({ page: params.currentPage })
            this.loadItems()
        },

        onPerPageChange(params) {
            this.updateParams({ perPage: params.currentPerPage })
            this.loadItems()
        },
        onSortChange(params) {
            this.updateParams({ sort: params })
            this.loadItems()
        },
        onColumnFilter(params) {
            this.updateParams(params)
            this.loadItems()
        },
        onSearch(params) {
            this.updateParams({ columnFilters: params.searchTerm })
            this.loadItems()
        },
        loadItems() {
            this.isLoading = true

            const params = JSON.parse(JSON.stringify(this.serverParams))

            backendApi.getWorkersAccount(store.state.login.user.token, params).then((data) => {
                this.remoteData.totalRecords = data.totalRecords
                this.remoteData.rows = data.rows
                this.updateParams({
                    page: data.currentPage,
                    perPage: data.maxResult,
                })
            })
        },
    },
    mounted() {
        this.loadItems()
    },
}
</script>

<style scoped></style>
